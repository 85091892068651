import { useCallback, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import {
  AutoCompleteLocationOption,
  AutoCompleteWithMobileModal,
} from '@reward-platform/lift/components'
import { AutoCompleteItemAttributes } from '@reward-platform/lift/components/AutoComplete/components/AutoComplete.types'
import { LocationOptionProps } from '@reward-platform/lift/components/AutoComplete/components/AutoCompleteLocationOption'
import { chakra, useMultiStyleConfig } from '@chakra-ui/react'
import useIntl from '~/hooks/useIntl/useIntl'
import useDebounce from '~/hooks/useDebounce/useDebounce'
import { useNotification } from '~/components/shared/Notifications'
import { getPartner } from '~/services/partnerService'
import { UseComboboxStateChange } from 'downshift'

interface SearchBoxProps<T> {
  selectedItem: AutoCompleteItemAttributes | null | undefined
  onChange: (value: T) => void
  name: string
  label: string
  value: string
  error?: string
  searchFunction: (partner: string, searchString: string) => Promise<T[]>
  getKey: (item: T) => string
  createAutoCompleteItem: (location: T) => LocationOptionProps
  onFieldCleared?: () => void
  extraQueryKeys?: Array<string>
}

export const SearchBox = <T,>({
  selectedItem,
  onChange,
  name,
  label,
  value,
  error,
  getKey,
  searchFunction,
  createAutoCompleteItem,
  onFieldCleared,
  extraQueryKeys,
}: SearchBoxProps<T>) => {
  const partner = getPartner().code
  const styles = useMultiStyleConfig('SearchHotelForm', {})
  const intl = useIntl()
  const { addNotification } = useNotification()
  const [searchString, setSearchString] = useState<string>('')

  const debouncedSearchString = useDebounce(searchString, 500)

  const querySameAsLabel = searchString === selectedItem?.label
  const canSearch =
    debouncedSearchString !== '' && debouncedSearchString === searchString && !querySameAsLabel

  const defaultQueryKey = ['SearchBox', name, partner, debouncedSearchString]
  const queryKey = extraQueryKeys ? [...defaultQueryKey, ...extraQueryKeys] : defaultQueryKey

  const { data: locations, isLoading } = useQuery(
    queryKey,
    () => searchFunction(partner, debouncedSearchString),
    {
      enabled: canSearch,
      onError: (e: Error) => addNotification(e.message),
    }
  )

  const noResultsFound = locations?.length === 0 && !isLoading && canSearch
  const fromAirportRef = useRef<HTMLInputElement>(null)
  const autocompleteItems = locations?.map(createAutoCompleteItem) ?? []

  const onInputValueChange = useCallback(
    (v: UseComboboxStateChange<AutoCompleteItemAttributes>) => {
      const inputValue = v.inputValue ?? ''
      setSearchString(inputValue)
      if (inputValue === '' && onFieldCleared) {
        onFieldCleared()
      }
    },
    [setSearchString, onFieldCleared]
  )

  const onSelectedItemChange = useCallback(
    (v: UseComboboxStateChange<AutoCompleteItemAttributes>) => {
      const key = v.selectedItem?.value
      const location = locations?.find((l) => getKey(l) === key)

      if (location) {
        onChange(location)
      }

      fromAirportRef.current?.blur()
    },
    [locations, onChange, getKey]
  )

  const t = {
    searching: intl.formatMessage({
      id: 'search-box-searching',
      defaultMessage: 'Searching...',
    }),
    noResultsFound: intl.formatMessage({
      id: 'search-box-no-results-found',
      defaultMessage: 'No results found or not a valid route.',
    }),
  }

  return (
    <AutoCompleteWithMobileModal
      ref={fromAirportRef}
      label={label}
      name={name}
      error={error}
      value={value}
      selectedItem={selectedItem}
      items={autocompleteItems}
      onInputValueChange={onInputValueChange}
      onSelectedItemChange={onSelectedItemChange}
    >
      {isLoading && <chakra.div __css={styles.destination}>{t.searching}</chakra.div>}
      {noResultsFound && <chakra.div __css={styles.destination}>{t.noResultsFound}</chakra.div>}
      {autocompleteItems.map((item, index) => {
        return <AutoCompleteLocationOption key={item.value} item={item} index={index} />
      })}
    </AutoCompleteWithMobileModal>
  )
}
