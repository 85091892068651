import React, { useCallback } from 'react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  IconButton,
  useMultiStyleConfig,
  Box,
  PopoverProps,
} from '@chakra-ui/react'

export type TooltipProps = PopoverProps & {
  content: React.ReactElement
  closeButtonText?: string
  triggerContent?: React.ReactElement
  icon?: React.ReactElement
  width?: number
  trigger?: 'hover' | 'click'
}

export const Tooltip = ({
  content,
  icon,
  closeButtonText,
  triggerContent,
  width,
  placement,
  variant,
  trigger = 'click',
  ...rest
}: TooltipProps) => {
  const style = useMultiStyleConfig('Tooltip', { variant })

  const popoverArrowPosition = useCallback(() => {
    if (placement?.includes('top')) {
      return {
        borderBottom: `${style.containerBorder.border}`,
        borderRight: `${style.containerBorder.border}`,
        top: `${style.topPos.top}`,
      }
    }

    if (placement?.includes('right')) {
      return {
        borderLeft: `${style.containerBorder.border}`,
        borderBottom: `${style.containerBorder.border}`,
        left: `-${style.topPos.top}`,
      }
    }

    return {
      borderTop: `${style.containerBorder.border}`,
      borderLeft: `${style.containerBorder.border}`,
      top: `-${style.topPos.top}`,
    }
  }, [placement, style.containerBorder.border, style.topPos.top])

  return (
    <Popover
      arrowSize={15}
      gutter={20}
      flip={false}
      placement={placement}
      trigger={trigger}
      {...rest}
    >
      <PopoverTrigger>
        {icon ? (
          <IconButton aria-label="information-icon" icon={icon} __css={style.iconButton} />
        ) : (
          triggerContent
        )}
      </PopoverTrigger>
      <PopoverContent
        width={width}
        border={`${style.containerBorder.border}`}
        borderColor={`${style.containerBorder.borderColor}`}
        bg={`${style.containerBg.bg}`}
      >
        <PopoverArrow
          {...popoverArrowPosition()}
          bg={`${style.containerBg.bg}`}
          borderColor={`${style.containerBorder.borderColor}`}
        />
        <Box __css={style.wrapper}>
          <PopoverBody>{content}</PopoverBody>
          {closeButtonText && <PopoverCloseButton>{closeButtonText}</PopoverCloseButton>}
        </Box>
      </PopoverContent>
    </Popover>
  )
}
