import { AirportLocation, AirportLocations } from '@reward-platform/flights-schemas'
import {
  Location,
  CarHireLocationSearch,
  LocationDataTransferObjectsSchema,
  CarHireLocationSearchDataTransferObjectsSchema,
} from '@reward-platform/ancillaries-schemas/location'
import { bffClient } from './clients/bffClient'
import { createServiceSchemaValidator } from './utils'
import { PlatformError } from '../utils/errors'

const validateLocation = createServiceSchemaValidator(LocationDataTransferObjectsSchema)
const validateCarHireLocation = createServiceSchemaValidator(
  CarHireLocationSearchDataTransferObjectsSchema
)

export async function getLocations(
  partner: string,
  searchType: string,
  searchString: string,
  sortHotels?: boolean,
  vendorBrandId?: string
): Promise<Location[] | CarHireLocationSearch[]> {
  if (!searchType || !searchString) {
    throw new PlatformError('Missing location parameter')
  }
  let query = `partner=${partner}&searchType=${searchType}&searchString=${searchString}`

  if (sortHotels) {
    query += '&sortHotels=true'
  }
  if (vendorBrandId) {
    query += `&vendorBrandId=${vendorBrandId}`
  }

  let url = '/search/locations'
  switch (searchType) {
    case 'HOTEL':
      url += `/hotel`
      break
    case 'EXPERIENCE':
      url += `/experience`
      break
    case 'CAR_HIRE':
      url += `/car-hire`
      break
    default:
      break
  }
  url += `?${query}`

  const { data } = await bffClient.get(url)

  const validator = searchType === 'CAR_HIRE' ? validateCarHireLocation : validateLocation
  return validator(data)
}

export async function getAirportLocations(
  searchString: string,
  partnerId: string,
  fromAirport?: string
): Promise<AirportLocations> {
  if (!searchString) {
    throw new PlatformError('Missing searchString parameter')
  }

  if (!partnerId) {
    throw new PlatformError('Missing partner identifier')
  }

  const fromAirportQuery = fromAirport ? `&fromAirport=${fromAirport}` : ''
  const partnerIdQuery = `&partnerIds=${partnerId}`

  const { data } = await bffClient.get(
    `/search/airports?searchString=${searchString}${fromAirportQuery}${partnerIdQuery}`
  )
  return data
}

export const isValidRoute = async (
  fromAirportCode: string,
  partnerId: string,
  toAirportCode: string
): Promise<boolean> => {
  const locations = await getAirportLocations(toAirportCode, partnerId, fromAirportCode)

  return locations.some((l: AirportLocation) => l.airportCode === toAirportCode.toUpperCase())
}

export const getAirport = async (airportCode: string): Promise<AirportLocation | null> => {
  const { data } = await bffClient.get(`/search/airport/${airportCode}`)
  return data
}
