import { AirportLocation } from '@reward-platform/flights-schemas'
import { useQuery } from 'react-query'
import { useNotification } from '~/components/shared/Notifications'
import { getAirport } from '~/services/locationService'

const useGetAirport = (airportCode?: string): AirportLocation | undefined => {
  const { addNotification } = useNotification()

  const { data } = useQuery(
    ['getAirport', airportCode],
    async () => {
      if (airportCode) {
        const airport = await getAirport(airportCode)
        if (airport == null) {
          throw new Error(`Airport not found`)
        }
        return airport
      }
      return undefined
    },
    {
      onError: (e: Error) => addNotification(e.message),
    }
  )

  return data
}

export default useGetAirport
