import { useQuery } from 'react-query'
import { usePartner } from '~/context/partner'
import { useUser } from '~/context/user'
import { getAviosOnlyPromotion } from '~/services/contentService'

export const useAviosOnlyPromotion = () => {
  const user = useUser()
  const partner = usePartner()

  const { data: aviosOnlyPromotionContent, isLoading } = useQuery(['aviosOnlyPromotion'], () =>
    getAviosOnlyPromotion(partner.theme, user.userLocale ?? 'en-GB')
  )

  return { aviosOnlyPromotionContent, isLoading }
}
